import { useTranslation } from 'react-i18next'
import { usePrivacyAccepted } from '../_app/store'

export default function Privacy() {
  const { privacyAccepted, setPrivacyAccepted } = usePrivacyAccepted()
  const { t } = useTranslation()

  if (privacyAccepted || window.location.pathname.includes('board')) {
    return null
  }

  return (
    <div
      className="fixed bottom-0 right-0 z-20 flex w-full justify-center border-t border-black bg-slate-50 shadow"
      data-cy="privacy">
      <div className="flex w-full max-w-screen-xl items-center justify-between py-2 px-6 ">
        <div className="flex flex-col justify-start">
          <span className="px-2">{t('privacyPolicyMessage')}</span>
          <div>
            <a
              href="https://agilix.dog/polityka-prywatnosci"
              target="_blank"
              aria-label="link do polityk polityki prywatności"
              rel="noreferrer"
              className="cursor-pointer  rounded-xl  px-2 underline hover:bg-slate-300">
              {t('privacyPolicy')}
            </a>
          </div>
        </div>

        <button type="button" className="btn-outline ml-4" onClick={() => setPrivacyAccepted(true)}>
          OK
        </button>
      </div>
    </div>
  )
}
