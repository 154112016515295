import { Context } from '../_types'
import SideNav from '../_app/components/sideNav.tsx'
import FloatingMenu from '../_app/components/floatingMenu.tsx'
import WithApp from '../_utils/withApp.tsx'
import RequestEmailVerification from '../_app/components/requestEmailVerification.tsx'
import Privacy from './privacy.tsx'

type Props = {
  context: Context
}

export default function ClientOnly({ context }: Props) {
  return (
    <WithApp context={context}>
      <div className="no-print">
        <Privacy />
        <FloatingMenu context={context} />
        <SideNav context={context} />
        <RequestEmailVerification />
      </div>
    </WithApp>
  )
}
