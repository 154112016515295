import { useLockScroll, useNavigationItems, useWhoAmIQuery } from '../utils/hooks'
import ChangeLanguage from '../../_layout/changeLanguage'
import { Context } from '../../_types'
import { useRoutes } from '../constants/routes'
import { useNavOpen } from '../store'
import RoundedImage from './roundedImage'

type Props = {
  context: Context
}

export default function SideNav({ context }: Props) {
  const nav = useNavigationItems()
  const { open, setOpen } = useNavOpen()
  const whoami = useWhoAmIQuery()
  const routes = useRoutes()

  useLockScroll(open)

  return (
    <>
      <div
        onClick={() => setOpen(false)}
        className={`${open ? 'visible opacity-40' : 'invisible opacity-0'}
        z-40 fixed h-screen w-screen bg-black opacity-20 duration-100 top-0 left-0`}
      />
      <div
        className={`z-40 fixed duration-300 w-[80%] ${open ? 'bottom-0' : 'bottom-[-1000px]'}
        ml-[10%]
        `}>
        <div className="flex overflow-auto justify-center w-full">
          <div className="bg-white pb-8 rounded-t w-full">
            <div className="flex justify-between w-full items-center p-2">
              <a href={routes.home()} onClick={() => setOpen(false)}>
                <img src="/agilix-logo.svg" className="h-20 w-20" alt="logo" />
              </a>

              {whoami.data?.whoAmI ? (
                <a
                  href={routes.profile()}
                  onClick={() => setOpen(false)}
                  className="hover:bg-highlight hover:text-white">
                  <div className="p-3 flex items-center justify-center flex-col gap-1">
                    <RoundedImage src={whoami.data.whoAmI.picture} size={50} />
                    <span className="underline">
                      {nav.find(item => item.key === 'navigation.profile')?.label}
                    </span>
                  </div>
                </a>
              ) : null}
            </div>

            <div className="flex w-full flex-col items-center space-y-5 px-6 py-10 gap-2">
              {nav.map(item => {
                if (item.key === 'navigation.profile') {
                  return null
                }

                return (
                  <a
                    key={item.key}
                    href={item.to}
                    onClick={() => setOpen(false)}
                    className="py-1 px-2 text-center text-xl bg-sky-100 hover:bg-highlight border border-solid border-stone-500 hover:text-white">
                    <span>{item.label}</span>
                  </a>
                )
              })}
            </div>

            <div className="flex w-full pl-8 ">
              <ChangeLanguage context={context} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
