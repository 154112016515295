import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useIsLoggedIn, useLogout, useWhoAmIQuery } from '../utils/hooks'
import { obfuscateEmail } from '../utils/utils'
import { gql } from '../../../__types__'
import Modal from './modal'
import OpenMessenger from './openMessenger'
import ChangeEmail from './changeEmail'

export default function RequestEmailVerification() {
  const logout = useLogout()
  const isLoggedIn = useIsLoggedIn()
  const { t } = useTranslation()
  const [changeEmail, setChangeEmail] = useState(false)
  const [havingProblems, setHavingProblems] = useState(false)
  const [emailConfirmed, setEmailConfirmed] = useState(false)

  const { data } = useWhoAmIQuery(emailConfirmed ? 999999999 : 2000)

  const [sendActivation] = useMutation(
    gql(
      `
      mutation sendActivationEmail {
        sendActivationEmail
      } 
    `,
    ),
    {
      refetchQueries: ['requiresPasswordReset'],
    },
  )

  useEffect(() => {
    if (data?.whoAmI?.requiresToResetPassword === false) {
      setEmailConfirmed(true)
    }
  }, [data?.whoAmI?.requiresToResetPassword])

  const requestActivation = useMemo(() => {
    if (!isLoggedIn) {
      return false
    }

    if (!data) {
      return false
    }

    if (!data.whoAmI.requiresToResetPassword) {
      return false
    }

    return true
  }, [isLoggedIn, data])

  useEffect(() => {
    if (requestActivation) {
      sendActivation()
    }
  }, [requestActivation, sendActivation])

  if (!requestActivation) {
    return null
  }

  return (
    <>
      <Modal
        dataCy="requestEmailVerification"
        isOpen={true}
        setOpen={() => null}
        title={t('emailActivate.requestActivateEmail')}
        hideClose>
        <div className="pb-4 flex flex-col gap-8">
          <div className="flex flex-col items-center sm:items-start sm:flex-row-reverse sm:justify-between gap-4 flex-1">
            <img src="/mailing.png" alt="mailing info image" className="w-40" />

            {changeEmail ? (
              <div className="flex-1 w-full">
                <ChangeEmail
                  onCancel={() => setChangeEmail(false)}
                  onSuccess={() => setChangeEmail(false)}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-2 sm:flex-row">
                  <span className="font-bold text-xl whitespace-nowrap" data-cy="obfustated-email">
                    {obfuscateEmail(data?.whoAmI.email ?? '')}
                  </span>
                  <button
                    data-cy="changeEmail"
                    className="underline text-gray-500"
                    onClick={() => {
                      setChangeEmail(true)
                    }}>
                    {t('emailActivate.wrongEmail')}
                  </button>
                </div>
                <span className="mt-2">{t('emailActivate.toContinue')}</span>
                <span className="caption">{t('emailActivate.suggestion')}</span>
              </div>
            )}
          </div>

          {havingProblems ? (
            <div className="flex flex-col items-center gap-2">
              <span>{t('emailActivate.contactUs')}</span>
              <OpenMessenger />
            </div>
          ) : (
            <div className="flex justify-between">
              <button
                className="underline"
                onClick={() => {
                  setHavingProblems(true)
                }}>
                {t('emailActivate.couldNotActivate')}
              </button>
              {changeEmail ? null : (
                <button
                  onClick={logout}
                  data-cy="profile.logout"
                  className="text-red-700 underline">
                  {t('profile.logout')}
                </button>
              )}
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}
