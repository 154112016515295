import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { isEmailValid } from '../pages/email/utils'
import { gql } from '../../../__types__'
import TextInput from './form/textInput'

type FormValues = {
  email: string
}

const fields: Record<keyof FormValues, keyof FormValues> = {
  email: 'email',
}

type Props = {
  onCancel: () => void
  onSuccess: () => void
}

export default function ChangeEmail({ onCancel, onSuccess }: Props) {
  const { t } = useTranslation()
  const [error, setError] = useState<string | null>(null)
  const [mutate, { loading }] = useMutation(
    gql(`
      mutation updateEmail($email: String!) {
        updateEmail(email: $email) {
          error
        }
      }
    `),
    {
      refetchQueries: ['whoAmI'],
      awaitRefetchQueries: true,
      onCompleted: resp => {
        if (resp.updateEmail.error) {
          setTimeout(() => {
            setError(null)
          }, 4000)

          return setError(resp.updateEmail.error)
        }

        onSuccess()
      },
    },
  )

  return (
    <div className="">
      <Formik
        initialValues={{ email: '' }}
        onSubmit={values =>
          mutate({
            variables: {
              email: values.email,
            },
          })
        }
        validate={values => {
          if (!isEmailValid(values.email)) {
            return {
              email: 'err',
            }
          }
        }}>
        <Form className="flex-1">
          <div className="flex flex-col gap-4 pr-4 ">
            <div className="flex flex-col">
              <TextInput
                name={fields.email}
                label={t('emailActivate.email')}
                autofocus
                stripSpaces
              />
              {error ? (
                <span className="ml-2 text-red-700 font-bold text-sm">{error}</span>
              ) : (
                <div className="h-5" />
              )}
            </div>

            <div className="flex justify-end gap-2">
              <button type="button" className="underline" onClick={onCancel}>
                {t('cancel')}
              </button>

              <button type="submit" className="btn" disabled={loading} data-cy="submit">
                {t('emailActivate.save')}
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}
